<!--
 * @Author: your name
 * @Date: 2022-03-30 10:19:20
 * @LastEditTime: 2022-04-01 15:40:53
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \MyBoke\src\pages\home\components\hotTools.vue
-->

<template>
  <div class="hotTool">
    <span>{{title}}</span>
    <div class="toolItem">
      <el-tabs v-model="title" class="demo-tabs">
        <el-tab-pane name="热门文章">
          <template #label>
            <span class="custom-tabs-label">
              <el-icon :size="18">
                <Pointer />
              </el-icon>
            </span>
          </template>
          <div class="_artile">
            <ArticleSmall
              @click="toPosts({ id: item.id })"
              :readOnly="true"
              :imgWidth="flexScreen != 'pad' ? '80px' : '100px'"
              :imgHeight="flexScreen != 'pad' ? '60px' : '60px'"
              :titleSize="flexScreen != 'pad' ? '14px' : '18px'"
              v-for="(item, index) in articleList"
              :key="item.id"
              :article="item"
              v-show="index < 3"
            >
            </ArticleSmall>
          </div>
        </el-tab-pane>
        <el-tab-pane name="最新评论" >
          <template #label>
            <span class="custom-tabs-label">
              <el-icon :size="18">
                <ChatLineSquare />
              </el-icon>
            </span>
          </template>
          <NewReply
            v-for="item in replyList"
            :key="item.id"
            :reply="item"
            @click="pingFn(item.id,item.ownerId,item.commentId)"
            :readOnly="true"
            :imgWidth="flexScreen != 'pad' ? '50px' : '50px'"
            :imgHeight="flexScreen != 'pad' ? '50px' : '50px'"
            :titleSize="flexScreen != 'pad' ? '14px' : '18px'"
          ></NewReply>
        </el-tab-pane>
        <el-tab-pane name="随机文章" >
          <template #label>
            <span class="custom-tabs-label">
              <el-icon :size="18">
                <MagicStick />
              </el-icon>
            </span>
          </template>
          <div class="magic">
            制作中...
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script setup>
import { Pointer, ChatLineSquare, MagicStick } from "@element-plus/icons-vue";
import { defineProps,ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import ArticleTool from "@/components/articleTool/articleTool";
import ArticleSmall from "@/components/articleList/articleSmall";
import NewReply from "@/components/reply/newReply";
import useScreen from "@/hooks/useScreen.js";
let router = useRouter();
let store = useStore();
let title = ref('热门文章')

// 去文章详情页
let toPosts = (params) => {
  router.push({
    name: "posts",
    params: { ...params },
  });
};

// 点击评论跳转到文章
let pingFn = (commentId,postId,parentId) => {
  store.commit("postInfo/UP_isFromComment", true);
  store.commit("postInfo/UP_fromCommentId", [commentId,parentId]);
  toPosts({id:postId});
};

let { flexScreen } = useScreen();
defineProps({
  articleList: {
    type: Array,
    default: () => {
      return [1];
    },
  },
  replyList: {
    type: Array,
    default: () => {
      return [1];
    },
  },
});
</script>

<style lang="scss" >
.magic{
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}
.ArticleSmall {
  padding: 0px 0;
  border-radius: 0;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .hotTool {
    padding: 0 !important;
  }
}
.hotTool * {
  box-sizing: border-box;
}
.hotTool {
  margin-top: 23px;
  padding: 0 0 0 10px;
  color: var(--white_divFc);
  .toolItem {
    margin-top: 15px;
    background-color: var(--white_divBgc);
    color: var(--white_divFc);
    height: auto;
    border-radius: 10px;
    padding: 5px;
    box-sizing: border-box;
  }

  // ._artile {
  //   height: auto;
  //   ._artileItem {
  //     display: flex;
  //     height: auto;
  //     min-width: 0;
  //     border-bottom: 1px solid var(--white_divBoc);
  //     border-radius: 0;
  //     ._artileItem_img {
  //       width: 80px;
  //       height: 80px;
  //       display: flex;
  //       justify-content: center;
  //       align-items: center;
  //       img {
  //         width: 50px;
  //         height: 50px;
  //         border-radius: 3px;
  //       }
  //     }

  //     ._artileItem_right {
  //       flex: 1;
  //       display: flex;
  //       flex-direction: column;
  //       min-width: 0;
  //       .title {
  //         padding: 0;
  //         height: 50px;
  //         display: flex;
  //         justify-content: flex-start;
  //         align-items: center;
  //         text-indent: 10px;
  //         span {
  //           font-size: 14px;
  //           max-height: 50px;
  //           display: inline-block;
  //           white-space: nowrap;
  //           overflow: hidden;
  //           text-overflow: ellipsis;
  //         }
  //       }
  //       .tool {
  //         padding: 0;
  //         height: 30px;
  //         max-height: 30px;
  //         font-size: 8px;
  //         display: flex;
  //         justify-content: flex-start;
  //         align-items: center;
  //       }
  //     }
  //   }
  // }
}
.custom-tabs-label {
  color: var(--white_divFc) !important;
}
.demo-tabs > .el-tabs__content {
  padding: 0 10px;
  color: var(--white_divFc);
  font-size: 24px;
}
.demo-tabs .custom-tabs-label .el-icon {
  vertical-align: middle;
}
.demo-tabs .custom-tabs-label span {
  vertical-align: middle;
  margin-left: 4px;
}
.el-tabs__item {
  /* padding: 0 20px; */
  height: 40px;
  box-sizing: border-box;
  line-height: 40px;
  display: inline-block;
  list-style: none;
  font-size: 14px;
  font-weight: 500;
  color: var(--el-text-color-primary);
  position: relative;
  flex: 1 !important;
  justify-content: center !important;
  display: flex !important;
}

.el-tabs__nav-wrap::after {
  width: 0 !important;
}
.el-tabs__active-bar {
  background-color: var(--white_divBoc) !important;
}

.el-tabs__nav {
  margin: 0 auto !important;
  width: 80% !important;
  display: flex !important;
}
.el-tabs__nav-scroll {
  display: flex !important;
}
</style>
