<!--
 * @Author: your name
 * @Date: 2022-03-30 14:44:18
 * @LastEditTime: 2022-04-30 18:14:39
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \MyBoke\src\components\articleList\articleSmall.vue
-->

<template>
  <div class="_artileItem ArticleSmall">
    <div class="_artileItem_img">
      <div>
        <img
          :style="{
            width: imgWidth,
            height: imgHeight,
          }"
          v-seen="{ type: 'lazy' }"
          :src="reply.avatar"
          alt=""
        />
      </div>
    </div>
    <div class="_artileItem_right">
      <div class="title">
        <span
          :style="{
            'font-size': titleSize,
          }"
        >
          {{ reply.content }}
        </span>
      </div>
      <slot name="description"> </slot>
      <div class="tool">
        {{ reply.created_at }}
        <!-- <ArticleTool
          @likeFn="readOnly ? null : likeFn(article.id)"
          @pingFn="readOnly ? null : pingFn()"
          :likeNum="readOnly ? article.likeNum : likeNum"
          :commentNum="readOnly ? article.commentNum : commentNum"
          :isLikedMe="readOnly ? false : isLikedMe"
        >
        </ArticleTool> -->
        <div class="btn">
          <slot name="btn"> </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed, withDefaults } from "vue";
import ArticleTool from "@/components/articleTool/articleTool";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import useLike from "../header/useLike";
let store = useStore();
let router = useRouter();
let useLikes = useLike();
let props = defineProps({
  readOnly: {
    type: Boolean,
    default: false,
  },
  titleSize: {
    type: String,
    default: "14px",
  },
  imgWidth: {
    type: String,
    default: "200px",
  },
  imgHeight: {
    type: String,
    default: "120px",
  },
  reply: {
    type: Object,
    default: () => {
      return {};
    },
  },
});
</script>
 
<style scoped lang="scss">
._artileItem {
  display: flex;
  height: auto;
  min-width: 0;
  // border: 1px solid var(--white_divBoc);
  background-color: var(--white_divBgc);
  border-radius: 10px;
  ._artileItem_img {
    padding: 15px 5px 15px 15px;
    box-sizing: border-box;
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    // border: 1px solid red;

    align-items: center;
    img {
      border-radius: 3px;
    }
  }

  ._artileItem_right {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: 0;
    .title {
      padding: 0;
      height: 45px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      // border: 1px solid red;
      font-weight: normal;
      text-indent: 10px;
      span {
        font-size: 14px;
        max-height: 50px;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .tool {
      padding: 0 10px;
      // border: 1px solid red;
      height: 30px;
      max-height: 30px;
      font-size: 14px;
      color: var(--white_divFc_light);
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btn {
        padding-right: 10px;
      }
    }
  }
}
</style>