<template>
  <el-affix :offset="60" @change="change">
    <div :class="{ tags: true }">
      <div :class="{ tags_top: true, white: isAffix }">
        <div class="tags_top_left">全部标签</div>
        <div class="tags_top_right"></div>
      </div>
      <div class="tagsList">
        <el-tag
          size="large"
          effect="dark"
          :color="item.color"
          :hit="true"
          v-for="item in tagsList"
          :key="item.id"
          @click="toTag(item.id)"
          >{{ item.name }}</el-tag
        >
      </div>
    </div>
  </el-affix>
</template>

<script>
import { toRefs, ref } from "vue";
import { useRouter } from "vue-router";
export default {
  name: "tags",
  props: ["tagsList"],
  setup(props) {
    let router = useRouter();
    let isAffix = ref(false);
    let change = (e) => {
      isAffix.value = e;
      console.log(e);
    };

    let toTag = (id) => {
      router.push({
        path: `/tag/${id}`,
      });
    };

    return {
      isAffix,
      change,
      toTag,
    };
  },
};
</script>

<style scoped="scoped">
.el-affix {
  width: auto !important;
}

.tagsList {
  margin-top: 20px;
  padding: 20px 10px;
  height: auto;
  background-color: var(--white_divBgc);
  border-radius: 10px;
}

.white {
  font-weight: 600;
  color: var(--white_divFc);
}

.el-tag {
  margin: 5px 5px 5px 5px;
  border: none !important;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
 .tags {
   padding: 0 !important;
 }

}

.tags {
  height: auto;
  padding: 0 0 0 10px;
}

.tags_top_left {
  flex: 1;
  color: var(--white_divFc);

  font-size: 18px;
  padding: 20px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.tags_top_right {
  flex: 1;
  color: var(--white_divFc);

  font-size: 18px;
  padding: 20px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.tags_top_right span {
  margin-right: 5px;
}

.tags_top {
  height: 50px;
  width: 100%;
  display: flex;
}
</style>
