/*
 * @Author: your name
 * @Date: 2022-03-11 17:10:41
 * @LastEditTime: 2022-05-04 20:04:25
 * @LastEditors: baymax-arch 1625750783@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \vue3Boke\src\pages\home\usePosts.js
 */
import { ref, reactive, onMounted, computed, watch } from 'vue'

import { getPosts, getHotsPost, isLikedFn, getNewReply } from '../../api/api.js'

import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import useLike from '../../components/header/useLike'

import { useGetters } from '../../store/utils/useGetters.js'

export default function (id) {
  let posts = reactive([]) //接收文章
  let moreFlag = ref(true) //控制加载更多按钮
  let scrollFlag = ref(true) //控制加载数据是否滚到底部
  let current = ref(1) //记录页码
  let total = ref(0) //记录页码
  let autoFlag = ref(false) //控制列表形式
  let moreLoading = ref(false) //控制列表形式
  let componentData = reactive({
    HotsPosts: [],
    NewReply: [],
  })

  //热门文章
  let store = useStore()
  let useLikes = useLike()
  let router = useRouter()
  let clear = () => {
    posts.length = 0
  }

  // 获取文章列表
  let getPostsFun = async params => {
    store.commit('postInfo/UP_postIdList', { type: 'clear' })
    let res = await getPosts(params)
    let { data: postsRes, pageSize, total:count } = res
    total.value = count
    posts.push(...postsRes)
    posts.forEach(item => {
      store.commit('postInfo/UP_postIdList', { type: 'add', value: item.id })
    })

    if (current.value == pageSize) {
      moreFlag.value = false
    } else {
      scrollFlag.value = true
    }
    // 结束loading
    moreLoading.value = false
  }

  // 根据id修改点赞状态
  let updateLikeStatus = (id, flag) => {
    let isHas = postsList.value.some(pItem => pItem.id == id)
    // 缓存有的话
    if (isHas) {
      console.log('缓存有')
      useLikes.updatePostList({
        filed: 'isLikedMe',
        id,
        value: flag,
      })
    }
    // 没有的话
    else {
      console.log('缓存么有')
      posts.forEach(item => {
        if (item.id == id) {
          item.isLikedMe = flag
        }
      })
    }
  }

  // 获取首页的文章id列表
  let { postIdList, postsList } = useGetters('postInfo', [
    'postIdList',
    'postsList',
  ])

  // 监听登陆状态为true的时候获取所有已存在列表的点赞状态
  watch(
    () => store.state.userInfo.isLogin,
    async newValue => {
      if (newValue) {
        postIdList.value.forEach(async item => {
          let isHas = postsList.value.some(pItem => pItem.id == item)
          if (!isHas) {
            // console.log('不存在，去数据库获取');
            // 获取点赞状态
            let { data } = await isLikedFn({ id: Number(item), type: 1 })
            let flag = !!data
            updateLikeStatus(item, flag)
          } else {
            let flag = postsList.value.filter(pItem => pItem.id == item)[0]
              .isLikedMe
            if (flag) {
              updateLikeStatus(item, flag)
            } else {
              let { data } = await isLikedFn({ id: Number(item), type: 1 })
              let flagLike = !!data
              updateLikeStatus(item, flagLike)
            }
          }
        })
      }
    },
    {
      deep: true,
    }
  )

  // 获取下一页文章
  let getMore = id => {
    // 开启loading
    moreLoading.value = true
    setTimeout(() => {
      current.value++
      getPostsFun({ id, current: current.value, limit: 5 })
    }, 300)
  }

  onMounted(() => {
    getPostsFun({ id })
    getHotsPost({ current: 1, limit: 5 }).then(res => {
      componentData.HotsPosts = res.data
    })
    getNewReply().then(res => {
      console.log(res)
      componentData.NewReply = res.data
    })
    console.log('posts')
  })

  let toUser = (id) => {
    router.push({ path: `/user/${id}` });
  };


  return {
    toUser,
    total,
    componentData,
    moreLoading,
    posts,
    moreFlag,
    getMore,
    scrollFlag,
    current,
    autoFlag,
    getPostsFun,
    clear,
    updateLikeStatus,
  }
}
