/*
 * @Author: your name
 * @Date: 2022-03-15 22:30:14
 * @LastEditTime: 2022-04-04 12:00:27
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \MyBoke\src\pages\home\useTags.js
 */
import { ref, onMounted } from 'vue'

import { getTags } from '../../api/api.js'

// const rsp = await $api.get(`/posts/${params.id}`)

export default function (path) {
  let tagsList = ref([])
  onMounted(async () => {
    let { data, total } = await getTags()
    tagsList.value = data
  })
  return {
    tagsList,
  }
}
